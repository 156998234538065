import { registerSW } from 'virtual:pwa-register';
const { log } = console;

export default registerSW({
	onNeedRefresh() {
		log('SW found an update');
		window.postMessage({ name: 'new-update' }, '*');
	},

	onRegisteredSW(swScriptUrl, registration) {
		log('SW registered');
		if (registration && registration.waiting) {
			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}
	},
});
