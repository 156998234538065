import { defineStore } from 'pinia';
import MLogout from '@modals/m-logout';
import {
	USER_INVALID_CRED,
	USER_NOT_FOUND,
	USER_WILL_BE_TEMP_BLOCKED,
	USER_WAS_TEMP_BLOCKED,
	USER_WILL_BE_PERMANENTLY_BLOCKED,
	REMEMBER_TOKEN_INVALID,
} from '@modules/service/constants';

import { useSecureStore } from '@modules/secure/m-secure';
import { useServiceStore } from '@modules/service/m-service';
import { useAppStore } from '@local-modules/app/m-app';
import { useDeviceStore } from '@modules/device/m-device';
import { useContractsStore } from '@modules/contracts/m-contracts';
import { useLoadingStore } from '@modules/loading/m-loading';
import { useModalStore } from '@modules/modal/m-modal';
import { useUserStore } from '@modules/user/m-user';
import { useNotificationsStore } from '@modules/notifications/m-notifications';
import { useCommunicationsStore } from '@modules/communications/m-communications';
import { useSessionStore } from '@/store/modules/session/m-session';
import { useSirvaseStore } from '@/store/modules/sirvase/m-sirvase';

import iconURL from '/icons/pwaIcon.png';

export const useAuthStore = defineStore('m-authn', {
	state: () => ({
		isLoggedIn: false,
		isMultiple: false,
		sessionRSI: null,
		hasInvestmentSkylineAllowed: false,
	}),

	getters: {
		getIsLoggedIn() {
			return this.isLoggedIn;
		},
	},
	actions: {
		setLoggedIn(value) {
			this.isLoggedIn = value;
		},
		setHasInvestmentSkylineAllowed(value) {
			this.hasInvestmentSkylineAllowed = value;
		},
		setIsMultiple(value) {
			this.isMultiple = value;
		},
		setSessionRSI(value) {
			this.sessionRSI = value;
		},
		/**
		 * LogIn an user with the username and password.
		 *
		 * @param {Object} payload
		 * @param {String} payload.rememberToken Token
		 * @param {String} payload.username Username
		 * @param {String} payload.password Password
		 * @param {Boolean} payload.isMyDevice Whether it is the user's device
		 * @param {String} payload.language Language setting
		 */

		async login({ rememberToken, username, password, isMyDevice, language }) {
			const secure = useSecureStore();
			const contractsStore = useContractsStore();
			const loadingStore = useLoadingStore();
			const appStore = useAppStore();
			const deviceStore = useDeviceStore();
			const sirvaseStore = useSirvaseStore();

			await secure.createSession();

			contractsStore.reset();
			sirvaseStore.reset();
			loadingStore.start();
			const url = '/login';
			const method = 'POST';
			const isCBNK = appStore.getIsCbnk;
			const companyId = appStore.getCompanyId;

			let loginMethod;
			let loginValue;

			if (rememberToken && !username) {
				loginMethod = 'rememberToken';
				loginValue = rememberToken;
			} else {
				loginMethod = 'documentId';
				loginValue = username;
			}

			const credentials = {
				[loginMethod]: loginValue,
				password,
				companyId,
				channel: 'WEB',
				deviceId: deviceStore.getDeviceId,
				isMyDevice,
				language,
			};

			const reasons = {
				BAD_CREDENTIALS: 1 << 0,
				BAD_PASSWORD: 1 << 1,
				BAD_USER: 1 << 2,
			};

			try {
				const serviceStore = useServiceStore();

				const { data } = await serviceStore.request({
					service: { request: { url, method } },
					payload: credentials,
				});
				if (data.requirePwdChange) {
					const modalStore = useModalStore();
					const prefix = isCBNK ? 'cbnk-' : '';
					const MPasswordChange = await import(`@modals/m-${prefix}password-change.vue`);
					const pwdChangeResponse = await modalStore.open({
						component: MPasswordChange,
					});

					if (pwdChangeResponse !== true) throw pwdChangeResponse;
				}
				if (isCBNK) {
					const sessionStore = useSessionStore();
					sessionStore.setVertical({
						vertical: data?.vertical,
						collective: data?.collective,
					});
				}
				await this.getContracts({ data: { username: data?.username } });
				return {
					...data,
					[loginMethod]: loginValue,
				};
			} catch (error) {
				loadingStore.end();

				const reason = { ...reasons };
				const { data = {} } = error?.response ?? {};

				reason.status = reasons.BAD_PASSWORD;

				if (data.errorCode === 'CHANGE_USER') {
					reason.status = reasons.BAD_USER;
				}

				if (data.errorCode === REMEMBER_TOKEN_INVALID) {
					reason.status = reasons.BAD_USER | reasons.BAD_CREDENTIALS;
				}

				if (
					data.errorCode === USER_INVALID_CRED ||
					data.errorCode === USER_NOT_FOUND ||
					data.errorCode === USER_WILL_BE_TEMP_BLOCKED ||
					data.errorCode === USER_WAS_TEMP_BLOCKED ||
					data.errorCode === USER_WILL_BE_PERMANENTLY_BLOCKED
				) {
					reason.status |= reasons.BAD_CREDENTIALS;
				}

				this.setLoggedIn(false);
				throw reason;
			}
		},

		loginByToken({ session }) {
			const secure = useSecureStore();
			const serviceStore = useServiceStore();
			const appStore = useAppStore();
			const deviceStore = useDeviceStore();

			if (appStore.getIsHybridSky) {
				return secure
					.createHybridSession()
					.then((data) => {
						this.setLoggedIn(true);
						return deviceStore
							.getDeviceData()
							.then(({ theme }) => ({ ...data, theme }))
							.catch(() => ({ ...data, theme: '' }));
					})
					.catch(() => Promise.reject());
			}

			const method = 'POST';
			const url = '/webview-login';

			return serviceStore
				.request({
					service: { request: { url, method } },
					payload: {
						deviceId: deviceStore.getDeviceId,
						tokenwebview: session,
					},
				})
				.then(() => this.setLoggedIn(true))
				.catch((err) => Promise.reject(err));
		},

		loginAnonymous() {
			const url = '/login';
			const method = 'POST';

			const serviceStore = useServiceStore();
			const deviceStore = useDeviceStore();
			const appStore = useAppStore();

			return serviceStore.request({
				service: { request: { url, method } },
				payload: {
					companyId: appStore.getCompanyId,
					deviceId: deviceStore.getDeviceId,
					channel: 'WEB',
				},
			});
		},

		async logout() {
			const secure = useSecureStore();
			const appStore = useAppStore();
			const loadingStore = useLoadingStore();
			const modalStore = useModalStore();
			const userStore = useUserStore();
			const notificationsStore = useNotificationsStore();
			const communicationsStore = useCommunicationsStore();
			const sessionStore = useSessionStore();

			const isHybrid = appStore.getIsHybrid;
			const isHybridSky = appStore.getIsHybridSky;
			const isEmbedded = appStore.getIsEmbedded;

			if (isEmbedded) {
				const userUUID = secure.getUuid;
				if (window.parent && userUUID) {
					window.parent.postMessage({ name: 'close-session', userUUID }, '*');
				}
			}

			if (isHybridSky) {
				window.dispatchEvent(new Event('native-exit-app'));
			}

			if (isHybrid) {
				window.postMessage({ name: 'logout' }, '*');
				window.postMessage({ name: 'bridge-exit-app' }, '*');
			}

			await modalStore.closeAll();
			await userStore.clearCache();
			await notificationsStore.closeAll();
			await secure.removeSession();
			await communicationsStore.postLoginDisplayed(false);
			sessionStore.setVertical({ vertical: 0, collective: 0 });

			this.setLoggedIn(false);

			if (!sessionStore.getRememberToken && !isEmbedded) {
				sessionStore.loadUserSession();
			}

			loadingStore.end();
		},

		async activeLogout() {
			const appStore = useAppStore();
			const modalStore = useModalStore();
			const sessionStore = useSessionStore();

			const isHybrid = appStore.getIsHybrid;
			const isHybridSky = appStore.getIsHybridSky;
			const isEmbedded = appStore.getIsEmbedded;

			const userConfirmation = await modalStore.open(MLogout);

			if (userConfirmation) {
				if (!isEmbedded && !isHybrid) {
					await sessionStore.deleteSession('session/deleteSession');
				}

				if (
					navigator.credentials &&
					navigator.credentials.preventSilentAccess &&
					!isHybridSky &&
					!isEmbedded
				) {
					navigator.credentials.preventSilentAccess();
				}

				await this.logout();
			}
		},

		async passiveLogout() {
			const appStore = useAppStore();
			const modalStore = useModalStore();

			const isCBNK = appStore.getIsCbnk;

			const prefix = isCBNK ? 'cbnk-' : '';
			const component = await import(`@modals/m-${prefix}expired-session.vue`);
			const openModal = () => modalStore.open(component);

			if (!appStore.getIsHybridSky) {
				await this.logout();
				await new Promise((resolve) => setTimeout(resolve, 500));
				await openModal();
			} else {
				await openModal();
				await this.logout();
			}
		},

		storeCredentials({ username, password }) {
			const deviceStore = useDeviceStore();

			if (deviceStore.getIsPWA && window.PasswordCredential) {
				const credential = new window.PasswordCredential({
					id: username,
					name: username,
					password,
					iconURL: new URL(iconURL, window.location.href),
				});
				navigator.credentials.store(credential);
			}
		},

		async getContracts({ data, source, origin }) {
			const secure = useSecureStore();
			const appStore = useAppStore();
			const contractsStore = useContractsStore();
			const loadingStore = useLoadingStore();
			const modalStore = useModalStore();
			const sessionStore = useSessionStore();

			loadingStore.start();

			const hasInvestmentSkylineAllowed = await this.hasInvestmentSkyline();

			const response = await contractsStore.get();
			const username = sessionStore.getUserName || data?.username;
			const userUUID = secure.getUuid;
			const { contracts = [] } = response;
			const isMultiple = contracts.length > 1;
			let [contract] = contracts;
			const userId = data?.userId;
			const errorData = {
				name: 'error',
				userUUID,
				userId,
			};

			if (appStore.getIsHybridSky) {
				window.dispatchEvent(new Event('native-hide-loading'));
			}

			if (!contract) {
				source?.postMessage({ ...errorData, text: 'DASHBOARD.NO_CONTRACTS.ERROR' }, origin);
				return Promise.reject(response);
			}

			if (contract && source && origin) {
				source.postMessage({ name: 'show-frame', userUUID, userId }, origin);
			}

			if (isMultiple || (contracts.length === 1 && contract?.status === 'BLOCKED')) {
				const MContracts = await import('@modals/m-contracts.vue');
				try {
					contract = await modalStore.open({
						component: MContracts,
						props: { contracts: response, username, modal: true },
					});
				} catch {
					/* empty */
				}
			}
			const connectedContract = await contractsStore.set(contract);
			if (!connectedContract?.id) {
				source?.postMessage({ ...errorData, text: 'DASHBOARD.SET_CONTRACTS.ERROR' }, origin);
				return Promise.reject(connectedContract);
			}

			if (hasInvestmentSkylineAllowed) {
				this.setHasInvestmentSkylineAllowed(true);
			}

			this.setLoggedIn(true);
			this.setIsMultiple(isMultiple);

			if (appStore.isHybridSky) {
				return Promise.resolve(username);
			}
		},

		/**
		 * Allow access to an agent.
		 */
		authorizeAccess({ data, source, origin }) {
			const secure = useSecureStore();
			const serviceStore = useServiceStore();
			const appStore = useAppStore();
			const loadingStore = useLoadingStore();
			const sessionStore = useSessionStore();

			loadingStore.start(null);

			const url = '/assisted-channels/impersonations';
			const method = 'POST';
			const { userId, vertical, collective } = data;
			const userUUID = secure.getUuid;
			const isCBNK = appStore.getIsCbnk;

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { userId },
				})
				.then(async () => {
					/* istanbul ignore if */
					if (isCBNK) {
						sessionStore.setVertical({
							vertical,
							collective,
						});
					}

					return this.getContracts({
						data,
						source,
						origin,
					});
				})
				.catch(() =>
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					)
				);
		},

		/**
		 * Create an user session uuid and
		 * associate it with the agent uuid session
		 */
		async createUserSession({ data, source, origin }) {
			const secure = useSecureStore();
			const serviceStore = useServiceStore();
			const sessionStore = useSessionStore();

			await secure.createSession(null);
			sessionStore.setUserSession({ userName: data?.username });

			const url = '/associate-uuids';
			const method = 'POST';
			const { agentUUID, userId } = data;
			const userUUID = secure.getUuid;

			return serviceStore
				.request({
					service: {
						headers: {
							'Content-Type': 'application/json',
							'uuid': agentUUID,
							'childuuid': userUUID,
						},
						request: {
							url,
							method,
						},
					},
				})
				.then(() =>
					source.postMessage(
						{
							name: 'session-is-ready',
							userUUID,
							userId,
						},
						origin
					)
				)
				.catch(() =>
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					)
				);
		},

		hasInvestmentSkyline() {
			const method = 'GET';
			const url = '/products/sheetModeAllowList';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data: { active } }) => active)
				.catch(() => false);
		},

		getRSISession() {
			const method = 'GET';
			const url = '/products/sso/rsi';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(({ data }) => this.setSessionRSI(data))
				.catch(() => this.setSessionRSI(null));
		},

		refresh({ skipLogout, updateRSISession }) {
			const url = '/keep-alive';
			const method = 'GET';

			const serviceStore = useServiceStore();

			return serviceStore
				.request({
					service: {
						request: {
							url,
							method,
						},
					},
				})
				.then(() => {
					/* istanbul ignore else */
					if (updateRSISession) {
						return this.getRSISession();
					}
				})
				.catch(() => {
					/* istanbul ignore else */
					if (!skipLogout) {
						return this.passiveLogout();
					}
				});
		},
	},
});
