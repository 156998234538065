<template>
	<a
		v-if="href"
		:class="classes"
		:href="href"
		role="button"
		v-bind="$attrs"
	>
		<span
			v-if="$slots.icon"
			class="c-button__icon"
		>
			<slot name="icon" />
		</span>
		<span class="c-button__label">
			<slot />
		</span>
	</a>
	<button
		v-else-if="!href && !loading"
		:class="classes"
		v-bind="$attrs"
	>
		<span
			v-if="$slots.icon && !loading"
			class="c-button__icon"
		>
			<slot name="icon" />
		</span>
		<span
			v-if="!loading"
			class="c-button__label"
		>
			<slot />
		</span>
	</button>
	<button
		v-else-if="loading"
		:class="classes"
		v-bind="$attrs"
	>
		<div class="c-button__dots-wrapper">
			<div class="c-button__dots first"></div>
			<div class="c-button__dots middle"></div>
			<div class="c-button__dots last"></div>
		</div>
	</button>
</template>

<script>
export default {
	name: 'c-button',

	inheritAttrs: false,

	props: {
		raised: { type: Boolean },
		unelevated: { type: Boolean },
		outlined: { type: Boolean },
		dense: { type: Boolean },
		href: {
			type: String,
			default: '',
		},
		loading: { type: Boolean },
	},

	computed: {
		classes() {
			return {
				'button': true,
				'button--raised': this.raised,
				'button--unelevated': this.unelevated,
				'button--outlined': this.outlined,
				'button--dense': this.dense,
				'button--loading': this.loading,
				'button--link': this.href,
				'text-m-medium': true,
			};
		},
	},
};
</script>

<style lang="scss" scoped>
// @todo: This button must be updated
.button {
	appearance: none;
	outline: none;
	user-select: none;
	display: inline-flex;
	position: relative;
	min-width: 64px;
	height: 48px;
	align-items: center;
	justify-content: center;
	padding: 0 8px 0 8px;
	border-radius: var(--border-radius-m);
	border: none;
	line-height: 1.14;
	letter-spacing: 0;
	text-decoration: none;
	text-transform: uppercase;
	box-sizing: border-box;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	white-space: nowrap;
	transition: transform 250ms;
	--focus-ring-padding: 10px;
}

.button.button--link {
	text-transform: none;
	text-align: center;
}

.button::-moz-focus-inner {
	padding: 0;
	border: 0;
}

.button:active {
	outline: none;
}

.button:disabled {
	cursor: default;
	pointer-events: none;
	color: RGBA(var(--color-text-disabled), 0.75);
	background: RGB(var(--color-accent-disabled));
}

.button.button--dense {
	border-radius: var(--border-radius-xs);
}

.button .c-button__icon > .c-icon {
	display: inline-block;
	width: 18px;
	height: 18px;
	font-size: 18px;
	vertical-align: top;
}

.c-button__icon {
	margin-right: 8px;
	margin-left: 0;
}

.c-button__label + .c-button__icon {
	margin-left: 8px;
	margin-right: 0;
}

.button :deep(svg) {
	fill: currentColor;
}

.button--raised .c-button__icon,
.button--unelevated .c-button__icon,
.button--outlined .c-button__icon {
	margin-left: -4px;
}

.button--raised .c-button__label + .c-button__icon,
.button--unelevated .c-button__label + .c-button__icon,
.button--outlined .c-button__label + .c-button__icon {
	margin-right: -4px;
}

.button--raised,
.button--unelevated {
	padding: 0 16px 0 16px;
}

.button--raised:disabled,
.button--unelevated:disabled {
	color: RGBA(var(--color-text-disabled), 0.75);
	background: RGB(var(--color-accent-disabled));
}

.button:not(.button--outlined):not(:disabled),
.button--raised:not(.button--outlined):not(:disabled),
.button--unelevated:not(.button--outlined):not(:disabled) {
	background: RGBA(var(--c-button-bg, var(--color-secondary)), var(--c-button-bg-opacity, 1));
}

.button:not(.button--outlined):not(:disabled),
.button--raised:not(.button--outlined):not(:disabled),
.button--unelevated:not(.button--outlined):not(:disabled) {
	color: RGB(var(--c-button-color, var(--color-text-primary-dark)));
}

.button--raised {
	box-shadow: 0 2px 4px 0 RGBA(var(--color-primary-dark), 0.25);
}

.button--raised:disabled {
	box-shadow:
		0px 0px 0px 0px rgba(0, 0, 0, 0.2),
		0px 0px 0px 0px rgba(0, 0, 0, 0.14),
		0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.button--outlined {
	border-style: solid;
	padding: 0 15px 0 15px;
	border-width: 1.5px;
}

.button--outlined:disabled {
	border-color: RGB(var(--color-text-disabled));
}

.button--outlined:not(:disabled) {
	color: RGB(var(--color-text-primary));
	background-color: RGB(var(--c-button-bg, var(--color-surface-light)));
	border-color: RGB(var(--color-secondary));
}

.button--dense {
	height: 40px;
	font-size: 0.8125rem;
}

.button::after {
	content: '';
	pointer-events: none;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	border-radius: inherit;
	background-color: RGB(var(--color-secondary));
	opacity: 0;
	transition: opacity 200ms;
}

.button:active::after {
	transition-duration: 75ms;
	opacity: 0.24;
}

.button--raised::after,
.button--unelevated::after {
	background-color: #fff;
}

.c-button__dots-wrapper {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.c-button__dots {
	display: inline-block;
	content: '';
	width: 8px;
	max-width: 8px;
	height: 8px;
	border-radius: 10px;
	background-color: RGB(var(--c-button-color, var(--color-text-primary-dark)));
	animation: dot-flashing 0.5s infinite alternate;

	&.first {
		animation-delay: 0s;
	}

	&.middle {
		animation-delay: 0.16s;
	}

	&.last {
		animation-delay: 0.33s;
	}
}

@keyframes dot-flashing {
	0% {
		transform: translateY(2px);
		background-color: currentColor;
		opacity: 0.2;
	}

	100% {
		transform: translateY(-2px);
		background-color: currentColor;
		opacity: 1;
	}
}
</style>
